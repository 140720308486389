
//配置路由相关信息
import Vue from 'vue'
import VueRouter from "vue-router"

Vue.use(VueRouter)

const router = new VueRouter({
    // mode: 'history',
    routes: [
        {
            path: "",
            redirect: "/index",
            // component: () => import("@/views/views"),
        },
        {
            path: '/index',
            name: 'index',
            component: () => import("@/views/index"),
        },
    ]
})

export default router;